import React from "react";
import Container from "../container";
import "./base.css";

const Layout = (props) => {
  const { children } = props;

  return <Container>{children}</Container>;
};

export default Layout;
