import React, { useEffect } from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import loadable from "@loadable/component";
import Helmet from "react-helmet";
import Layout from "../components/base/layout/index";
import AOS from "aos";
import "aos/dist/aos.css";

/*
  Helper function to lazy load components on the fly
  based on conent model names. If it doesn't exist, load 
  a default empty component 
*/
const loadComponent = (component, index) => {
  const componentName = component.__typename
    .replace("Contentful", "")
    .toLowerCase();

  const Component = loadable(() =>
    import(`../components/contentful/${componentName}/index.js`)
  );

  return <Component key={`${component}-${index}`} data={component} />;
};

/* Page Template */
class PageTemplate extends React.Component {
  /* AOS */
  componentDidMount() {
    AOS.init();
    AOS.refresh();
  }

  render() {
    const pageData = get(this.props, "data.contentfulPage");

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={pageData.pageTitle}
          meta={[
            {
              name: "description",
              content: pageData.metaDescription.metaDescription,
            },
            { name: "keywords", content: pageData.metaKeywords.metaKeywords },
            {
              property: `og:title`,
              content: pageData.pageTitle,
            },
            {
              property: `og:description`,
              content: pageData.metaDescription.metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `og:image`,
              content: pageData.metaImage.url,
            },
            {
              name: `twitter:card`,
              content: pageData.metaDescription.metaDescription,
            },
            {
              name: `twitter:creator`,
              content: "AP Robotics",
            },
            {
              name: `twitter:title`,
              content: pageData.pageTitle,
            },
            {
              name: `twitter:description`,
              content: pageData.metaDescription.metaDescription,
            },
            {
              name: `twitter:image`,
              content: pageData.metaImage.url,
            },
          ]}
        >
          <html lang="en" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        {/* Use React suspense does not work with SSR, use loadable instead */}
        {pageData.components.map((component, index) => {
          return loadComponent(component, index);
        })}
      </Layout>
    );
  }
}

export default PageTemplate;

/*
  This is giant, but it has to be: Gatsby won't let you do string interpolation 
  on a GQL query.
*/
export const pageQuery = graphql`
  query PageBySlug($pageSlug: String!) {
    contentfulPage(pageSlug: { eq: $pageSlug }, sys: {}) {
      pageTitle
      metaDescription {
        metaDescription
      }
      metaKeywords {
        metaKeywords
      }
      metaImage {
        file {
          url
        }
      }
      components {
        __typename
        ... on ContentfulHero {
          imageAltText
          heading
          helperText
          type
          description {
            childMarkdownRemark {
              html
            }
          }
          primaryButton {
            link
            name
            theme
          }
          secondaryButton {
            link
            name
            theme
          }
          image {
            file {
              url
            }
          }
        }
        ... on ContentfulVideo {
          id
          videoLink
          videoAltText
          videoThumbnail {
            file {
              url
            }
          }
        }
        ... on ContentfulIntegrations {
          id
          heading
          description {
            childMarkdownRemark {
              html
            }
          }
          integrations {
            image {
              file {
                url
              }
            }
            imageAltText
            link
            companyName
          }
        }
        ... on ContentfulWhy {
          heading
          whyCallouts {
            name
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              file {
                url
              }
            }
            imageAltText
          }
        }
        ... on ContentfulContactForm {
          heading
          formDescription
          hubspotPortalId
          hubspotFormId
        }
        ... on ContentfulAboutUs {
          id
          helperText
          heading
          description {
            childMarkdownRemark {
              html
            }
          }
          primaryButton {
            name
            link
            theme
          }
          secondaryButton {
            name
            link
            theme
          }
        }
        ... on ContentfulProductToggle {
          id
          productList {
            buttonName
            buttonIcon {
              file {
                url
              }
              description
            }
            buttonAltText
            heading
            productDescription {
              childMarkdownRemark {
                html
              }
            }
            callouts {
              icon {
                file {
                  url
                }
              }
              iconAltText
              name
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
            video {
              videoLink
              videoAltText
              videoThumbnail {
                file {
                  url
                }
              }
            }
            pricing {
              helperText
              heading
              priceCards {
                type
                price
                limit
                frequency
                detailText
                primaryButton {
                  link
                  name
                  theme
                }
              }
            }
          }
        }
        ... on ContentfulNav {
          type
          links {
            name
            link
            category
            showInTopNav
          }
          ctaText
          ctaButton {
            link
            name
            theme
          }
          copyrightText
        }
      }
    }
  }
`;
